import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
// import {QuickViewComponent} from "../../modal/quick-view/quick-view.component";
// import {CartModalComponent} from "../../modal/cart-modal/cart-modal.component";
import {Product} from "../../../../shared/classes/product";
import {ProductService} from "../product.service";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: 'app-product-box-one',
  templateUrl: './product-box-one.component.html',
  styleUrls: ['./product-box-one.component.scss']
})
export class ProductBoxOneComponent implements OnInit {

  @Input() product: Product = {
    images: [{src: ''}]
  };
  @Input() currency: any = {name: 'Рубль', currency: 'RUB', price: 1}; // Default Currency
  @Input() thumbnail: boolean = false; // Default False
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;

  // @ViewChild("quickView") QuickView: QuickViewComponent;
  // @ViewChild("cartModal") CartModal: CartModalComponent;

  @Output() clickedEvent = new EventEmitter()

  file_api_url = environment.file_api_url;

  public ImageSrc : string | undefined

  constructor(
    private productService: ProductService
  ) {
  }

  ngOnInit(): void {
    if(this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
    this.ImageSrc =  `${this.file_api_url}/${this.product?.collectionId}/${this.product?.id}/${this.product?.images && this.product?.images[0]}?thumb=100x300`;
  }

  // Get Product Color


  clickAction(){
    this.clickedEvent.emit();
  }

}
