import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {TranslateModule} from '@ngx-translate/core';

// Header and Footer Components
// import {FooterOneComponent} from './footer/footer-one/footer-one.component';


// Components
import {LeftMenuComponent} from './components/left-menu/left-menu.component';

import {CategoriesComponent} from './components/categories/categories.component';
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {ProductBoxOneComponent} from '../feature-modules/pages/products/product-box-one/product-box-one.component';

// Tap To Top

// Pipes
import {DiscountPipe} from './pipes/discount.pipe';
import {ConsultModalComponent} from '../feature-modules/modals/consult-modal/consult-modal.component';
import {InfoModalComponent} from '../feature-modules/modals/info-modal/info-modal.component';
import { LazyLoadImageDirective } from "./directive/lazy-load-image.directive";
import { SkeletonProductBoxComponent } from "./components/skeleton/skeleton-product-box/skeleton-product-box.component";
import { LazyBackgroundDirective } from "./directive/app-lazy-background.directive";

@NgModule({
  declarations: [
    LeftMenuComponent,
    BreadcrumbComponent,
    CategoriesComponent,
    ProductBoxOneComponent,
    // LayoutBoxComponent,
    DiscountPipe,
    InfoModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    TranslateModule,
    LazyLoadImageDirective,
    LazyBackgroundDirective,
    SkeletonProductBoxComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    TranslateModule,
    BreadcrumbComponent,
    CategoriesComponent,
    ProductBoxOneComponent,
    InfoModalComponent,
    DiscountPipe,
    RouterModule,
  ]
})
export class SharedModule { }
