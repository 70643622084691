export interface Countries {
  code: string;
  code3: string;
  name: string;
  number: string;
}

export const COUNTRIES_CODE: Countries[] = [
  { name: 'Armenia', code: 'AM', code3: 'ARM', number: '374' },
  { name: 'Russia', code: 'RU', code3: 'RUS', number: '7' },
  { name: 'Kazakhstan', code: 'KZ', code3: 'KAZ', number: '7' },
  { name: 'Uzbekistan', code: 'UZ', code3: 'UZB', number: '998' },
];

export const PHONE_NUMBERS: string[] = ['+7 977 175 0 295', '+7 499 899 7 899', '+8 800 444 6 164'];

export const ADDRESSES: {link: string, label: string}[] = [
  {
    link: 'https://maps.app.goo.gl/scGfJwrakgZzUf3NA',
    label: 'Москва, Огородный проезд, 5, стр. 7'
  },
  {
    link: 'https://maps.app.goo.gl/awTh7YvX4okvmEB19',
    label: 'Метро Строгино, Маршала Прошлякова 19'
  }
];
