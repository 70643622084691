<div *ngIf="!loader">
  <div class="img-wrapper">
    <div class="lable-block">
      <span class="lable3" *ngIf="product && product.new">new</span>
      <span class="lable4" *ngIf="product?.sale_price">on sale</span>
    </div>
    <div class="front">
      <a (click)="clickAction()">
<!--        <img lazy-load-image [lazyLoadImageSrc]="collection.image" [offset]="-100" [title]="collection.title" [alt]="collection.title" class="img-fluid">-->

        <img
          lazy-load-image
          [lazyLoadImageSrc]="ImageSrc ? ImageSrc :  ((product.images && product.images[0] && product.images[0].src) || '')"
          class="img-fluid lazy-loading"
          alt="{{product.images &&   product.images[0] &&  product.images[0].alt }}" />
      </a>
    </div>
<!--  TODO - [B] -  On hover image-->
<!--    <div class="back" *ngIf="onHowerChangeImage">-->
<!--      <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">-->
<!--        <img [src]="ImageSrc ? ImageSrc : product.images[1].src" class="img-fluid lazy-loading" alt="{{ product.images[1].alt }}">-->
<!--      </a>-->
<!--    </div>-->
<!--    <ul class="product-thumb-list" *ngIf="thumbnail">-->
<!--      <li class="grid_thumb_img" [class.active]="ImageSrc == image.src" *ngFor="let image of product.images">-->
<!--        <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image.src)">-->
<!--&lt;!&ndash;          <img [lazyLoad]="image.src">&ndash;&gt;-->
<!--        </a>-->
<!--      </li>-->
<!--    </ul>-->
<!--    <div class="cart-info cart-wrap">-->
<!--      <a href="javascript:void(0)" title="Add to cart" (click)="CartModal.openModal(product)"-->
<!--        *ngIf="cartModal">-->
<!--        <i class="ti-shopping-cart"></i>-->
<!--      </a>-->
<!--      <a href="javascript:void(0)" title="Add to cart" (click)="addToCart(product)"-->
<!--        *ngIf="!cartModal">-->
<!--        <i class="ti-shopping-cart"></i>-->
<!--      </a>-->
<!--      <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">-->
<!--        <i class="ti-heart" aria-hidden="true"></i>-->
<!--      </a>-->
<!--      <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">-->
<!--        <i class="ti-search" aria-hidden="true"></i>-->
<!--      </a>-->
<!--      <a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">-->
<!--        <i class="ti-reload" aria-hidden="true"></i>-->
<!--      </a>-->
<!--    </div>-->
  </div>
  <div class="product-detail">
    <div>
<!--      <bar-rating [rate]="5" [readOnly]="true"></bar-rating>-->
<!--      <a [routerLink]="['/shop/product/left/sidebar/', product?.title?.replace(' ', '-')]">-->
<!--        <h6>{{ product?.title | titlecase }}</h6>-->
<!--      </a>-->
      <p>{{ product.description }}</p>
      <h4>
<!--        {{ ((product?.price * currency?.price) || 0) | discount:product | currency:currency?.currency:'symbol' }}-->
<!--        <del *ngIf="product?.discount"><span class="money"> {{( (product?.price * currency?.price) || 0) | currency:currency?.currency:'symbol' }}</span></del>-->
      </h4>
<!--      <ul class="color-variant" *ngIf="Color(product?.variants).length">-->
<!--        <li [class]="color" *ngFor="let color of Color(product?.variants)" [ngStyle]="{'background-color': color}"-->
<!--          (click)="ChangeVariants(color, product)">-->
<!--        </li>-->
<!--      </ul>-->
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<!--<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>-->
<!--<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>-->

