import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {Product} from '../../../shared/classes/product';
import {DataStateEnum} from "../../../enum/data-state.enum";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {InfoModalService} from "../../modals/info-modal/info-modal.service";
import {GetRequestService} from "../../../service/base/get-reqeust.service";
import {isPlatformBrowser} from "@angular/common";
import {environment} from "../../../../environments/environment";

const state: any = {
    products:  [],
    wishlist: [],
    compare: [],
    cart: [],
}

@Injectable({
    providedIn: 'root'
})
export class ProductService {

    private readonly apiUrl = `${environment.adminAppUrl}`;

    public Currency = {name: 'Рубль', currency: 'RUB', price: 1}; // Russian Currency
    public Products: any;

    constructor(private http: HttpClient,
                private getRequestService: GetRequestService,
                private modalService: NgbModal,
                private infoModalService: InfoModalService,
                @Inject(PLATFORM_ID) private platformId: any
    ) {
    }

    /*
      ---------------------------------------------
      ---------------  Product  -------------------
      ---------------------------------------------
    */

    openOrderCall() {
      if (isPlatformBrowser(this.platformId)) {
        import('../../modals/consult-modal/consult-modal.component').then(r =>
        {
          console.log('modal open process222', r.ConsultModalComponent)
          this.modalService?.open(r.ConsultModalComponent, {
            size: 'md',
            ariaLabelledBy: 'size-modal',
            centered: true,
            windowClass: ''
          }).result.then((result) => {
            `Result ${result}`
          }, (result) => {
            if (result?.dataState == DataStateEnum.LOADED_STATE) {
              this.infoModalService.showMessage({message: 'Заказ на звонок принят!'}, 'success');
            }
            if (result?.dataState === DataStateEnum.ERROR_STATE) {
              this.infoModalService.showMessage({
                message: 'К сожалению, произошла ошибка при обработке вашего запроса на ' +
                  'звонок. Пожалуйста, попробуйте ещё раз позже или свяжитесь с нами напрямую.!'
              }, 'error');
            }
          });
        })
      }
    }

    // Product

    private get products(): Observable<Product[]> {
        this.Products = this.http.get<Product[]>('assets/data/products.json').pipe(map(data => data));
        this.Products.subscribe((next: any) => {
            localStorage['products'] = JSON.stringify(next)
        });
        return this.Products = this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
    }

    // Get Products
    public get getProducts(): Observable<Product[]> {
        return this.products;
    }



    /*
      ---------------------------------------------
      -----------------  Cart  --------------------
      ---------------------------------------------
    */

    // Get Cart Items
    public get cartItems(): Observable<Product[]> {
        const itemsStream = new Observable(observer => {
            observer.next(state.cart);
            observer.complete();
        });
        return <Observable<Product[]>>itemsStream;
    }


    /*
      ---------------------------------------------
      ------------- Product Pagination  -----------
      ---------------------------------------------
    */
    public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 16) {
        // calculate total pages
        let totalPages = Math.ceil(totalItems / pageSize);

        // Paginate Range
        let paginateRange = 3;

        // ensure current page isn't out of range
        if (currentPage < 1) {
            currentPage = 1;
        } else if (currentPage > totalPages) {
            currentPage = totalPages;
        }

        let startPage: number, endPage: number;
        if (totalPages <= 5) {
            startPage = 1;
            endPage = totalPages;
        } else if (currentPage < paginateRange - 1) {
            startPage = 1;
            endPage = startPage + paginateRange - 1;
        } else {
            startPage = currentPage - 1;
            endPage = currentPage + 1;
        }

        // calculate start and end item indexes
        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex + 1,
            endIndex: endIndex + 1,
            pages: pages
        };
    }

    // Project Integration Changes
    public filterProducts(collectionName: string, data: any): Observable<any> {
      return this.getRequestService.search(data,`${this.apiUrl}/products/records`);
    }

    public getProduct(collectionName: string, id: string): Observable<any> {
      return this.getRequestService.getAll(`${this.apiUrl}/${collectionName}/records/${id}`);
    }

}
