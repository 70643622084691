import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

// import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent implements OnInit {
  type: 'success' | 'error' | undefined;
  message = 'MMMM DAAAA DDD CCCCC FFFFFF JJJJJ SSS  AAA';
  @ViewChild('infoModal', { static: false }) infoModal: TemplateRef<any> | undefined;


  constructor(
      private modalService: NgbModal,
      private ngbActiveModal: NgbActiveModal,
      // @Inject(MAT_DIALOG_DATA) data
  ) {
    // if (data != null) {
    //   this.infoText = data.data;
    // }
  }

  ngOnInit() {};

  openInfoModal(){
    this.modalService.open(this.infoModal, {
      size: 'md',
      ariaLabelledBy: 'size-modal',
      centered: true,
      windowClass: 'SizeChart'
    }).result.then((result) => {
      `Result ${result}`
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    }
  close() {
    this.ngbActiveModal.dismiss();
  }
}



