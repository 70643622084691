import { HostListener, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { isPlatformBrowser } from "@angular/common";
import { BaseListResponse } from "../../../interface/base-response";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";

// Menu
export interface Menu {
    path?: string;
    title?: string;
    type?: string;
    megaMenu?: boolean;
    image?: string;
    active?: boolean;
    badge?: boolean;
    badgeText?: string;
    queryParams?: any;
    children?: Menu[];
}

@Injectable({
    providedIn: 'root'
})

export class NavService {
  private readonly apiUrl = `${environment.adminAppUrl}/catalogs/records`;

    constructor(
      // private getRequestService: GetRequestService,
      private http: HttpClient,
      @Inject(PLATFORM_ID) private platformId: any,) {

    }

    public screenWidth: any;
    public leftMenuToggle: boolean = false;
    public mainMenuToggle: boolean = false;

    // Windows width
    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
      if (isPlatformBrowser(this.platformId)) {
        this.screenWidth = window.innerWidth;
      }
    }

// {
// 	name: 'Главная',
// 	iconName: '',
// 	pageUrl: '',
// },
// {
// 	name: 'Каталоги',
// 		iconName: '',
// 	children: [
// 	{
// 		name: 'Сопутствующие товары',
// 		iconName: '',
// 		pageUrl: 'goods',
// 	}
// ]
// },
// {
// 	name: 'Услуги',
// 		iconName: '',
// 	pageUrl: 'services',
// 	children: [
// 	{
// 		name: 'Алмазная резка',
// 		iconName: '',
// 		pageUrl: 'services/diamond-service',
// 	},
// 	{
// 		name: 'Выездная резка',
// 		iconName: '',
// 		pageUrl: 'services/outside-service',
// 	},
// 	{
// 		name: 'Укладка плитки',
// 		iconName: '',
// 		pageUrl: 'services/tile-lay-service',
// 	},
// 	{
// 		name: 'Гидроабразивная резка',
// 		iconName: '',
// 		pageUrl: 'services/waterjet-service',
// 	},
// ]
// },
// {
// 	name: 'Aкции',
// 		iconName: '',
// 	pageUrl: 'promotions',
// },
// {
// 	name: 'Контакты',
// 		iconName: '',
// 	pageUrl: 'contacts',
// },

    MENUITEMS2: Menu[] = [
        {
            title: 'Главная', type: 'sub', active: false,
        },
        {
            title: 'Каталоги', type: 'sub', active: false, children: [
                {path: '/home/fashion', title: 'Сопутствующие товары', type: 'link'},
            ],
        },
        {
            title: 'Услуги', type: 'sub', active: false, children: [
                {path: '/home/fashion', title: 'Алмазная резка', type: 'link'},
                {path: '/home/fashion', title: 'Выездная резка', type: 'link'},
                {path: '/home/fashion', title: 'Укладка плитки', type: 'link'},
                {path: '/home/fashion', title: 'Гидроабразивная резка', type: 'link'},
            ],
        },
        {
            title: 'Aкции', type: 'sub', active: false,
        },
        {
            title: 'Контакты', type: 'sub', active: false,
        },
        {
            title: 'home', type: 'sub', active: false, children: [
                {
                    title: 'clothing', type: 'sub', active: false, children: [
                        {path: '/home/fashion', title: 'fashion-01', type: 'link'},
                        {path: '/home/fashion-2', title: 'fashion-02', type: 'link'},
                        {path: '/home/fashion-3', title: 'fashion-03', type: 'link'}
                    ]
                },
                {path: '/home/vegetable', title: 'vegetable', type: 'link'},
                {path: '/home/watch', title: 'watch', type: 'link'},
                {path: '/home/landing-page', title: 'landing-page', type: 'link'},
                {path: '/home/flower', title: 'flower', type: 'link'},
                {path: '/home/beauty', title: 'beauty', type: 'link'},
                {path: '/home/electronics', title: 'electronics', type: 'link'},
                {path: '/home/pets', title: 'pets', type: 'link'},
                {path: '/home/gym', title: 'gym', type: 'link'},
                {path: '/home/tools', title: 'tools', type: 'link'},
                {path: '/home/shoes', title: 'shoes', type: 'link'},
                {path: '/home/bags', title: 'bags', type: 'link'},
                {path: '/home/marijuana', title: 'marijuana', type: 'link'}
            ]
        },
    ];

    MENUITEMS: Menu[] = [
        // {
        //     title: 'home', type: 'sub', active: false, children: [
        //         {
        //             title: 'clothing', type: 'sub', active: false, children: [
        //                 {path: '/home/fashion', title: 'fashion-01', type: 'link'},
        //                 {path: '/home/fashion-2', title: 'fashion-02', type: 'link'},
        //                 {path: '/home/fashion-3', title: 'fashion-03', type: 'link'}
        //             ]
        //         },
        //         {path: '/home/vegetable', title: 'vegetable', type: 'link'},
        //         {path: '/home/watch', title: 'watch', type: 'link'},
        //         {path: '/home/landing-page', title: 'landing-page', type: 'link'},
        //         {path: '/home/flower', title: 'flower', type: 'link'},
        //         {path: '/home/beauty', title: 'beauty', type: 'link'},
        //         {path: '/home/electronics', title: 'electronics', type: 'link'},
        //         {path: '/home/pets', title: 'pets', type: 'link'},
        //         {path: '/home/gym', title: 'gym', type: 'link'},
        //         {path: '/home/tools', title: 'tools', type: 'link'},
        //         {path: '/home/shoes', title: 'shoes', type: 'link'},
        //         {path: '/home/bags', title: 'bags', type: 'link'},
        //         {path: '/home/marijuana', title: 'marijuana', type: 'link'}
        //     ]
        // },
        {
            title: 'Услуги', type: 'sub', active: false, children: [
                {path: '/pages/services/diamond-service', title: 'Алмазная резка', type: 'link'},
                {path: '/pages/services/outside-service', title: 'Выездная резка', type: 'link'},
                {path: '/pages/services/tile-lay-service', title: 'Укладка плитки', type: 'link'},
                {path: '/pages/services/waterjet-service', title: 'Гидроабразивная резка', type: 'link'}
            ]
        },
        {
            title: 'Контакты', type: 'link', active: false, path: '/pages/contact'
        },
        {
            title: 'О Нас', type: 'link', active: false, path: '/pages/faq'
        },
        {
            title: 'Отзывы', type: 'link', active: false, path: '/pages/feedback'
        },

    ];

    // LEFTMENUITEMS: Menu[] = [
    //     {
    //         title: 'clothing', type: 'sub', megaMenu: true, active: false, children: [
    //             {
    //                 title: 'mens fashion', type: 'link', active: false, children: [
    //                     {path: '/home/fashion', title: 'sports wear', type: 'link'},
    //                     {path: '/home/fashion', title: 'top', type: 'link'},
    //                     {path: '/home/fashion', title: 'bottom', type: 'link'},
    //                     {path: '/home/fashion', title: 'ethic wear', type: 'link'},
    //                     {path: '/home/fashion', title: 'sports wear', type: 'link'},
    //                     {path: '/home/fashion', title: 'shirts', type: 'link'},
    //                     {path: '/home/fashion', title: 'bottom', type: 'link'},
    //                     {path: '/home/fashion', title: 'ethic wear', type: 'link'},
    //                     {path: '/home/fashion', title: 'sports wear', type: 'link'}
    //                 ]
    //             },
    //             {
    //                 title: 'women fashion', type: 'link', active: false, children: [
    //                     {path: '/home/fashion', title: 'dresses', type: 'link'},
    //                     {path: '/home/fashion', title: 'skirts', type: 'link'},
    //                     {path: '/home/fashion', title: 'westarn wear', type: 'link'},
    //                     {path: '/home/fashion', title: 'ethic wear', type: 'link'},
    //                     {path: '/home/fashion', title: 'bottom', type: 'link'},
    //                     {path: '/home/fashion', title: 'ethic wear', type: 'link'},
    //                     {path: '/home/fashion', title: 'sports wear', type: 'link'},
    //                     {path: '/home/fashion', title: 'sports wear', type: 'link'},
    //                     {path: '/home/fashion', title: 'bottom wear', type: 'link'}
    //                 ]
    //             },
    //         ]
    //     },
    //     {
    //         title: 'bags', type: 'sub', active: false, children: [
    //             {path: '/home/fashion', title: 'shopper bags', type: 'link'},
    //             {path: '/home/fashion', title: 'laptop bags', type: 'link'},
    //             {path: '/home/fashion', title: 'clutches', type: 'link'},
    //             {
    //                 path: '/home/fashion', title: 'purses', type: 'link', active: false, children: [
    //                     {path: '/home/fashion', title: 'purses', type: 'link'},
    //                     {path: '/home/fashion', title: 'wallets', type: 'link'},
    //                     {path: '/home/fashion', title: 'leathers', type: 'link'},
    //                     {path: '/home/fashion', title: 'satchels', type: 'link'}
    //                 ]
    //             },
    //         ]
    //     },
    //     {
    //         title: 'footwear', type: 'sub', active: false, children: [
    //             {path: '/home/fashion', title: 'sport shoes', type: 'link'},
    //             {path: '/home/fashion', title: 'formal shoes', type: 'link'},
    //             {path: '/home/fashion', title: 'casual shoes', type: 'link'}
    //         ]
    //     },
    //     {
    //         path: '/home/fashion', title: 'watches', type: 'link'
    //     },
    //     {
    //         title: 'Accessories', type: 'sub', active: false, children: [
    //             {path: '/home/fashion', title: 'fashion jewellery', type: 'link'},
    //             {path: '/home/fashion', title: 'caps and hats', type: 'link'},
    //             {path: '/home/fashion', title: 'precious jewellery', type: 'link'},
    //             {
    //                 path: '/home/fashion', title: 'more..', type: 'link', active: false, children: [
    //                     {path: '/home/fashion', title: 'necklaces', type: 'link'},
    //                     {path: '/home/fashion', title: 'earrings', type: 'link'},
    //                     {path: '/home/fashion', title: 'rings & wrist wear', type: 'link'},
    //                     {
    //                         path: '/home/fashion', title: 'more...', type: 'link', active: false, children: [
    //                             {path: '/home/fashion', title: 'ties', type: 'link'},
    //                             {path: '/home/fashion', title: 'cufflinks', type: 'link'},
    //                             {path: '/home/fashion', title: 'pockets squares', type: 'link'},
    //                             {path: '/home/fashion', title: 'helmets', type: 'link'},
    //                             {path: '/home/fashion', title: 'scarves', type: 'link'},
    //                             {
    //                                 path: '/home/fashion', title: 'more...', type: 'link', active: false, children: [
    //                                     {path: '/home/fashion', title: 'accessory gift sets', type: 'link'},
    //                                     {path: '/home/fashion', title: 'travel accessories', type: 'link'},
    //                                     {path: '/home/fashion', title: 'phone cases', type: 'link'}
    //                                 ]
    //                             },
    //                         ]
    //                     }
    //                 ]
    //             },
    //         ]
    //     },
    //     {
    //         path: '/home/fashion', title: 'house of design', type: 'link'
    //     },
    //     {
    //         title: 'beauty & personal care', type: 'sub', active: false, children: [
    //             {path: '/home/fashion', title: 'makeup', type: 'link'},
    //             {path: '/home/fashion', title: 'skincare', type: 'link'},
    //             {path: '/home/fashion', title: 'premium beaty', type: 'link'},
    //             {
    //                 path: '/home/fashion', title: 'more..', type: 'link', active: false, children: [
    //                     {path: '/home/fashion', title: 'fragrances', type: 'link'},
    //                     {path: '/home/fashion', title: 'luxury beauty', type: 'link'},
    //                     {path: '/home/fashion', title: 'hair care', type: 'link'},
    //                     {path: '/home/fashion', title: 'tools & brushes', type: 'link'}
    //                 ]
    //             },
    //         ]
    //     },
    //     {
    //         path: '/home/fashion', title: 'home & decor', type: 'link'
    //     },
    //     {
    //         path: '/home/fashion', title: 'kitchen', type: 'link'
    //     }
    // ];

    // Array
    items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
    // tslint:disable-next-line:indent
    // leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);

    get itemsList(){
      return this.items.asObservable();
    }

    getCatalogList(): Observable<BaseListResponse<any>> {
        return this.http
          .get<any>(`${this.apiUrl}`)
          .pipe();

      // return this.getRequestService.getAll(this.apiUrl);
      // return new Observable<BaseListResponse<any>>()
    }

}
