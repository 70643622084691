<!-- Info Modal -->
<div id="infoModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 *ngIf="type === 'success'" class="modal-title alert alert-success w-100">
                    Успешно
                </h5>
                <h5 *ngIf="type === 'error'" class="modal-title alert alert-warning w-100">
                    Ошибка
                </h5>
            </div>
            <div class="modal-body">
                <div class="h5">
                    {{message}}
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-solid" data-bs-dismiss="modal" (click)="close()">Закрыть</button>
            </div>
        </div>
    </div>
</div>

