import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {catchError, Observable, throwError} from "rxjs";
import { BaseListResponse } from "../../../interface/base-response";

@Injectable({
  providedIn: 'root'
})
export class GetRequestService {

  constructor(private http: HttpClient) {
  }

  search(params: any, uri: any): Observable<BaseListResponse<any>> {
    return this.http
      .get<any>(`${uri}`, {params: params})
      .pipe(catchError(this.handleError));
  }

  getAll(path: any): Observable<BaseListResponse<any>> {
    return this.http
      .get<any>(`${path}`)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    // TODO use ErrorFactory
    return throwError(`An error occurred - Error code ${error.status}`);
  }
}
