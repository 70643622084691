import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  Renderer2
} from '@angular/core';
import { isPlatformBrowser } from "@angular/common";

@Directive({
  standalone: true,
  selector: '[lazy-load-image]'
})
export class LazyLoadImageDirective implements OnInit, AfterViewInit{

  @Input() placeholderImage: string = '';
  @Input() lazyLoadImageSrc!: string;
  @Input() offset: number = 100;
  private isImageLoaded: boolean = false;

  constructor(private el: ElementRef, private renderer: Renderer2, @Inject(PLATFORM_ID) private platformId: any,
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      const rect = this.el.nativeElement.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const isImageVisible =
        rect.bottom >= 0 && (rect.top - this.offset) <= windowHeight;

      if (isImageVisible) {
        this.loadImage();
      } else {
        this.renderer.setAttribute(
          this.el.nativeElement,
          'src',
          this.placeholderImage
        );
      }
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    if (!this.isImageLoaded) {
      if (isPlatformBrowser(this.platformId)) {
        const rect = this.el.nativeElement.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const isImageVisible =
          rect.bottom >= 0 && (rect.top - this.offset) <= windowHeight;
        if (isImageVisible) {
          console.log('trueee222')

          this.loadImage();
        }
      }
    }
  }

  private loadImage() {
    this.renderer.setAttribute(
      this.el.nativeElement,
      'src',
      this.lazyLoadImageSrc
    );
    this.isImageLoaded = true;
  }


}
