import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Inject,
  Input,
  PLATFORM_ID,
  Renderer2
} from "@angular/core";
import { isPlatformBrowser } from "@angular/common";

@Directive({
  standalone: true,
  selector: '[appLazyBackground]'
})

export class LazyBackgroundDirective implements AfterViewInit {
  @Input() appLazyBackground: string | undefined;

  constructor(private el: ElementRef,
              private renderer: Renderer2,
              private cdRef: ChangeDetectorRef,
              @Inject(PLATFORM_ID) private platformId: Object) {
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      console.log('is browser-side logic')

      // This code will only run in the browser
      const obs = new IntersectionObserver((entries) => {
        entries.forEach(({isIntersecting}) => {
          if (isIntersecting) {
            this.renderer.setStyle(this.el.nativeElement, 'background-image', `url(${this.appLazyBackground})`);
            obs.unobserve(this.el.nativeElement);
          }
        });
      }, {
        rootMargin: '0px',
        threshold: 0.1
      });

      obs.observe(this.el.nativeElement);
      this.cdRef.markForCheck();
    } else {
      // Server-side logic (fallback)
      console.log('Server-side logic')
      this.renderer.setStyle(this.el.nativeElement, 'background-image', 'url(/path/to/low-res-fallback.jpg)');
    }
  }
}
