import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {InfoModalComponent} from './info-modal.component';

@Injectable({
    providedIn: 'root'
})
export class InfoModalService {

    constructor(
        private modalService: NgbModal,
    ) {
    }

    showMessage(data: any, type: string) {
        const modalRef = this.modalService.open(InfoModalComponent, {
            size: 'md',
            ariaLabelledBy: 'size-modal',
            centered: true,
            windowClass: 'SizeChart'
        });

        modalRef.componentInstance.message = data.message;
        modalRef.componentInstance.type = type;
        modalRef.result.then((result) => {
            `Result ${result}`
        }, (reason) => {});
    }
}
